<template>
  <div class="user-bar flex justify-between items-center">
    <div class="extra">
      <slot name="extra"></slot>
    </div>

    <div class="flex items-center justify-end">
      <InviteButton v-if="!isPersonalOrg" :blue="true" />
      <a-popover
        placement="bottomLeft"
        trigger="click"
        :overlayClassName="'right-popover'"
        v-model:visible="popoverVisible"
      >
        <div class="avatar">
          <Avatar size="32" class="editImg" :uuid="userInfo?.avatar" :name="userInfo?.name">
          </Avatar>
        </div>
        <template #content>
          <div class="content">
            <div class="item">
              <div class="item-avatar">
                <Avatar size="50" class="editImg" :uuid="userInfo?.avatar" :name="userInfo?.name">
                </Avatar>
              </div>
              <div class="item-first">
                {{ userInfo?.name }}
              </div>
            </div>
            <a-divider />

            <div class="item">
              <div class="item-avatar">
                <Icon code="xe67d;" style="margin-right: 12px; margin-top: 2px" :size="16"> </Icon>
              </div>
              <a-popover
                placement="topRight"
                trigger="hover"
                :overlayClassName="'avatar-popover'"
                v-model:visible="organizationPopoverVisible"
              >
                <div class="item-content">切换企业</div>
                <template #content>
                  <div
                    class="item"
                    :class="{ current: organizationId === o.id }"
                    v-for="o in organization"
                    :key="o.id"
                    @click="goChangeOrganization(o.id)"
                  >
                    <div class="item-avatar">
                      <Avatar size="40" class="editImg" :name="o.organizationName"> </Avatar>
                    </div>
                    <div class="item-content">
                      <div class="content-one">{{ o.organizationName }}</div>
                      <div class="content-two">基础版</div>
                    </div>
                    <div class="current-mark" v-if="organizationId === o.id">
                      <CheckOutlined />
                    </div>
                  </div>
                  <!-- <div class="item">
              <div class="item-avatar">
                <Icon code="xe610;" style="margin-right: 8px;" :size="32"></Icon>
              </div>
              <div class="item-content">
                <div class="content-one">chengxiang的企业</div>
                <div class="content-member">
                  <div class="content-two">基础版</div>
                  <div class="member">20成员</div>
                </div>
              </div>
            </div> -->
                  <a-divider />
                  <div class="item-add" @click="openCreateModal">
                    <div class="item-avatar">
                      <Icon code="xe891;" :size="15" color="#268feb"> </Icon>
                    </div>
                    <div class="create-content">创建企业</div>
                  </div>
                </template>
              </a-popover>
            </div>

            <a-tooltip
              title="个人空间不能邀请成员"
              placement="topLeft"
              :visible="inviteTooltipVisible && isPersonalOrg"
              @visibleChange="(v: boolean) => (inviteTooltipVisible = v)"
            >
              <div class="item" :class="{ disabled: userInfo?.organizationType === 'personal' }">
                <div class="item-avatar">
                  <Icon code="xe643;" style="margin-right: 12px; margin-top: 2px" :size="16">
                  </Icon>
                </div>
                <div class="item-content" @click="handleInviteVisible(true)">邀请成员</div>
              </div>
            </a-tooltip>
            <a-divider />
            <div class="item" @click="goHome">
              <div class="item-avatar">
                <Icon code="xe609;" style="margin-right: 12px; margin-top: 2px" :size="16"> </Icon>
              </div>
              <div class="item-content">官网首页</div>
            </div>
            <div class="item">
              <div class="item-avatar">
                <Icon code="xe634;" style="margin-right: 12px; margin-top: 2px" :size="16"> </Icon>
              </div>
              <div class="item-content" @click="goPersonalSettings">个人设置</div>
            </div>
            <a-divider />
            <div class="item">
              <div class="item-avatar">
                <Icon code="xe892;" style="margin-right: 12px; margin-top: 2px" :size="16"> </Icon>
              </div>
              <div class="item-content" @click="logout">退出登录</div>
            </div>
          </div>
        </template>
      </a-popover>
    </div>
    <!-- <Dropdown :trigger="['click']">
      <div class="avatar"></div>
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="0">
            <template #icon>
              <Icon code="xe612;" style="margin:0 12px; cursor: pointer" :size="32"></Icon>
            </template>
            <div class="operate-name">开始设计</div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="logout"> 退出登录 </a-menu-item>
        </a-menu>
      </template>
    </Dropdown> -->
  </div>
  <InviteModal
    :visible="inviteVisible"
    @close="handleInviteVisible(false)"
    :inviteToken="inviteToken"
  />
  <Modal v-model:visible="createVisible" title="创建企业" @ok="handleOkCreate">
    <a-form
      labelAlign="left"
      ref="createFormRef"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 20 }"
      :model="createForm"
    >
      <BasicFormItem
        v-for="item in createDefs"
        :key="item.key"
        :item="item"
        v-model:value="createForm[item.key]"
      />
    </a-form>
  </Modal>
</template>

<script setup lang="ts">
import { Dropdown } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { CheckOutlined } from '@ant-design/icons-vue'
import type { MenuInfo } from 'ant-design-vue/lib/menu/src/interface'
import { Icon } from '@elecmap/icon'
import { onMounted, reactive, ref } from 'vue'
import { Avatar, Modal, BasicFormItem } from '@elecmap/elecmap-ui'
import { listOrganization, changeOrganization } from '@elecmap/api/src/ram'
import { useOrganization } from '@/store/organization'
import useUserStore from '@/store/user'
import { storeToRefs } from 'pinia'

import { generateInvitedToken } from '@elecmap/api/src/ram'

import InviteModal from '../views/member-manage/invite-modal.vue'
import InviteButton from './InviteButton.vue'
import type { IFormItem, IKeywords } from '@elecmap/elecmap-ui/lib/form/types'
import { useSpaceStore } from '@/store/space'

const userStore = useUserStore()
const router = useRouter()

const { setUser } = useUserStore()
const { userInfo, isPersonalOrg } = storeToRefs(useUserStore())
const store = useOrganization()
const { setCurrentOrganizationId } = store
const { organization, organizationId } = storeToRefs(store)

const goChangeOrganization = (id: number) => {
  popoverVisible.value = false
  organizationPopoverVisible.value = false
  changeOrganization(id).then(res => {
    localStorage.removeItem('organizationId')
    setCurrentOrganizationId(id, res.organizationType)
    setUser(res)
    localStorage.setItem('refresh', '1')
    useSpaceStore().navs = []
    window.location.replace('/workbench')
    window.location.reload()
  })
}

const popoverVisible = ref(false)
const organizationPopoverVisible = ref(false)

const inviteVisible = ref(false)
const inviteToken = ref()

const inviteTooltipVisible = ref(false)

const handleInviteVisible = (visible: boolean) => {
  if (isPersonalOrg.value) return
  popoverVisible.value = false
  inviteVisible.value = visible
  if (visible) {
    generateInvitedToken().then((res: any) => {
      inviteToken.value = res
    })
  }
}

function goHome() {
  router.push('/')
}

function handleMenuClick(info: MenuInfo) {
  switch (info.key) {
    case 'logout':
      logout()
      break
    default:
      break
  }
}

function logout() {
  userStore.logout().then(() => {
    router.push('/login')
    const ss = useSpaceStore()
    ss.orgSpaceList = []
    ss.orgFolderList = []
    ss.orgProjectList = []
  })
}

const goPersonalSettings = () => {
  router.push('/settings/settings')
  popoverVisible.value = false
}

// onMounted(() => {
//   listOrganization().then((data = []) => {
//     setOrganization(data)
//   })
// })

const createFormRef = ref()
const createVisible = ref(false)
const createForm = ref<IKeywords>({})
const createDefs = reactive<IFormItem[]>([
  {
    key: 'name',
    label: '企业名称',
    placeholder: '请输入企业名称',
    type: 'Input',
    rules: [
      {
        required: true,
        message: '请输入',
      },
    ],
  },
  {
    key: 'name',
    label: '你的昵称',
    placeholder: '请输入昵称',
    type: 'Input',
    rules: [
      {
        required: true,
        message: '请输入',
      },
    ],
  },
  {
    key: 'name',
    label: '规模',
    placeholder: '请选择规模',
    type: 'Select',
  },
  {
    key: 'name',
    label: '行业',
    placeholder: '请选择行业',
    type: 'Select',
  },
])
const openCreateModal = () => {
  popoverVisible.value = false
  organizationPopoverVisible.value = false
  createVisible.value = true
}
const handleOkCreate = () => {
  // createFormRef.value.validate().then(() => { })

  createVisible.value = false
}
</script>

<style>
.right-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    width: 285px;

    .content {
      margin-top: 20px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 20px;
        cursor: pointer;

        .item-first {
          font-size: 14px;
          font-weight: 600;
          margin-left: 16px;
        }

        .item-content {
          font-size: 13px;
        }

        &.disabled {
          color: #00000040;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.user-bar {
  height: 60px;
  padding: 8px 16px;
  border-bottom: 1px solid hsla(240, 3%, 86%, 0.6);
  background-color: white;

  .avatar {
    width: 32px;
    height: 32px;
    background-color: #f0f0f0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
  }
}
</style>
