import { createRouter, createWebHashHistory } from 'vue-router'
import WorkbenchLayout from '../layout/WorkbenchLayout.vue'
import SettingLayout from '../layout/SettingLayout.vue'
import useUserStore from '../store/user'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      component: () => import('../views/login/login.vue'),
    },
    {
      path: '/',
      component: () => import('../views/index/Index.vue'),
      children: [
        {
          path: '',
          component: () => import('../views/index/Product.vue'),
        },
        {
          path: 'enterprise',
          component: () => import('../views/index/Enterprise.vue'),
        },
        {
          path: 'price',
          component: () => import('../views/index/Price.vue'),
        },
      ],
    },
    {
      path: '/workbench',
      component: WorkbenchLayout,
      redirect: '/workbench/index',
      children: [
        {
          path: 'index',
          component: () => import('../views/work-bench/work-bench.vue'),
        },
        {
          path: 'personal',
          name: 'personal',
          component: () => import('../views/work-bench/work-bench-personal.vue'),
        },
        {
          path: 'recent',
          component: () => import('../views/work-bench/recently-used.vue'),
        },
        {
          path: 'star',
          component: () => import('../views/work-bench/my-favorite.vue'),
        },
        {
          path: 'setting',
          component: () => import('../views/work-bench/work-bench.vue'),
        },
        {
          path: '/settings/settings',
          component: () => import('../views/user-setting/user-setting.vue'),
        },
      ],
    },
    {
      path: '/settings',
      component: SettingLayout,
      redirect: '/settings/member',
      children: [
        {
          path: 'member',
          component: () => import('../views/member-manage/member-manage.vue'),
        },
        {
          path: 'space',
          component: () => import('../views/space-manage/space-manage.vue'),
        },
        {
          path: 'project',
          component: () => import('../views/project-manage/project-manage.vue'),
        },
      ],
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../views/invite/invite-employee-index.vue'),
    },
  ],
})

const whiteList = ['/', '/enterprise', '/price', '/login']

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()

  if (!userStore.isLogin && !whiteList.includes(to.path)) {
    next('/login')
    return
  }

  next()
})

export default router
