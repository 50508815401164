<template>
  <section class="flex h-100">
    <nav class="nav">
      <a-popover
        placement="bottomLeft"
        trigger="click"
        :overlayClassName="'avatar-popover'"
        v-model:visible="popoverVisible"
      >
        <div class="logo-section cursor-pointer">
          <template
            v-if="currentOrganization && currentOrganization.organizationName !== '个人空间'"
          >
            <div class="flex items-center flex-1 overflow-hidden">
              <Avatar size="28" :name="currentOrganization.organizationName"></Avatar>
              <div class="elec org">{{ currentOrganization.organizationName }}</div>
              <caret-down-outlined style="color: rgb(170, 170, 170); font-size: 12px" />
            </div>
          </template>
          <template v-else>
            <div class="click-area">
              <div class="elec logo">Electrical Ace</div>
              <!-- <div class="map"></div> -->
              <caret-down-outlined style="color: rgb(170, 170, 170); font-size: 12px" />
            </div>
          </template>
          <span class="free">免费版</span>
        </div>

        <template #content>
          <div
            class="item"
            :class="{ current: organizationId === o.id }"
            v-for="o in organization"
            :key="o.id"
            @click="goChangeOrganization(o.id)"
          >
            <div class="item-avatar">
              <Avatar size="40" class="editImg" :name="o.organizationName"> </Avatar>
            </div>
            <div class="item-content">
              <div class="content-one">{{ o.organizationName }}</div>
              <div class="content-two">基础版</div>
            </div>
            <div class="current-mark" v-if="organizationId === o.id">
              <CheckOutlined />
            </div>
          </div>
          <!-- <div class="item">
              <div class="item-avatar">
                <Icon code="xe610;" style="margin-right: 8px;" :size="32"></Icon>
              </div>
              <div class="item-content">
                <div class="content-one">chengxiang的企业</div>
                <div class="content-member">
                  <div class="content-two">基础版</div>
                  <div class="member">20成员</div>
                </div>
              </div>
            </div> -->
          <a-divider />
          <div class="item-add" @click="openCreateModal">
            <div class="item-avatar">
              <Icon code="xe891;" :size="15" color="#268feb"> </Icon>
            </div>
            <div class="create-content">创建企业</div>
          </div>
        </template>
      </a-popover>
      <a-button type="primary" size="small" @click="toDesignCenter" class="button"
        >进入设计中心</a-button
      >

      <div class="menu-item">
        <div
          class="item-menu"
          :class="{ active: route.path === '/workbench/recent' }"
          @click="go('/workbench/recent')"
        >
          <div class="item-one">
            <Icon code="xe681;" style="margin-right: 8px" :size="16"></Icon>
          </div>
          <div class="item-two">最近使用</div>
        </div>
        <div
          class="item-menu"
          :class="{ active: route.path === '/workbench/star' }"
          @click="go('/workbench/star')"
        >
          <div class="item-one">
            <Icon code="xe666;" style="margin-right: 8px" :size="16"></Icon>
          </div>
          <div class="item-two">我的收藏</div>
        </div>
        <div
          v-if="currentOrganization?.organizationName !== '个人空间'"
          class="item-menu"
          :class="{ active: route.path === '/workbench/personal' }"
          @click="go('/workbench/personal')"
        >
          <div class="item-one">
            <Icon code="xe60f;" style="margin-right: 8px" :size="14"></Icon>
          </div>
          <div class="item-two">我的空间</div>
        </div>
        <div class="item-menu" :class="{ active: false }">
          <div class="item-one">
            <Icon code="xe646;" style="margin-right: 8px" :size="16"></Icon>
          </div>
          <div class="item-two">与我协作</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="organizational-space">
        <div class="space-first">
          <div class="first-left">存储空间</div>
          <a-tooltip title="新建空间">
            <div class="first-right" @click="createSpace">
              <PlusOutlined />
            </div>
          </a-tooltip>
        </div>
        <!-- <div class="space-item">
          <div class="space-left">
            <Icon code="xe60f;" style="margin-right: 10px; padding-bottom: 3px" :size="14"></Icon>
            <div class="left-text">公共空间</div>
          </div>
          <a-popover
            placement="right"
            trigger="click"
            :overlayClassName="'space-content'"
            v-model:visible="spacePopoverVisible"
          >
            <div class="space-right">
              <Icon
                code="xe719;"
                style="margin-left: 12px; transform: translateX(3px); cursor: pointer"
                :size="16"
              >
              </Icon>
            </div>
            <template #content>
              <div class="space-popover">
                <Icon code="xeb50;" :size="18"> </Icon>
                <div class="space-name" @click="changePermission">空间权限</div>
              </div>
            </template>
          </a-popover>
        </div> -->

        <div
          v-for="item in orgSpaceList"
          :key="item.id"
          class="space-item"
          :class="{ active: item.id === currentSpace?.id }"
          @click="selectSpace(item)"
        >
          <div class="space-left">
            <Icon code="xe60f;" :size="14"></Icon>
            <div class="left-text">{{ item.name }}</div>
          </div>
          <!-- <a-popover placement="right" trigger="click" :overlayClassName="'space-content'"
            v-model:visible="spacePopoverVisible"> -->
          <a-dropdown
            :trigger="['click']"
            :overlayClassName="'space-dropdown'"
            placement="bottomLeft"
          >
            <Menu
              icon-class="iconfont2"
              :menus="spaceMenus"
              @click="handleSpaceMenuClick($event, item)"
            >
              <Icon code="xe719;" :size="16" :gutter="0"> </Icon>
            </Menu>
            <div class="space-right"></div>
            <!-- <template #overlay>
              <a-menu>
                <a-menu-item key="0" @click="changePermission(item)">
                  <template #icon>
                    <Icon code="xe60e;" style="margin: 0 12px; cursor: pointer" :size="18"></Icon>
                  </template>
                  <div class="operate-name">空间权限</div>
                </a-menu-item>
                <a-menu-item v-if="item.type !== 1" key="1">
                  <template #icon>
                    <Icon code="xe63a;" style="margin: 0 12px; cursor: pointer" :size="18"></Icon>
                  </template>
                  <div class="operate-name" @click="handleRenameSpace(item)">修改信息</div>
                </a-menu-item>
                <a-menu-divider v-if="item.type !== 1" />
                <a-menu-item v-if="item.type !== 1" @click.stop="handleDeleteSpace(item)">
                  <template #icon>
                    <Icon code="xe718;" style="margin: 0 12px; cursor: pointer" :size="18"></Icon>
                  </template>
                  <div class="operate-name">删除</div>
                </a-menu-item>
              </a-menu>
            </template> -->
          </a-dropdown>
          <!-- <template #content>
              <div class="space-popover">
                <Icon code="xeb50;" :size="18">
                </Icon>
                <div class="space-name" @click="changePermission">空间权限</div>
              </div>
            </template>
          </a-popover> -->
        </div>
      </div>

      <div class="bottom-line"></div>
      <div class="bottom" :class="{ 'bottom-only': userInfo.organizationType === 'personal' }">
        <div
          v-if="userInfo.organizationType === 'personal'"
          class="bottom-left"
          @click="goPersonalSettings"
        >
          <Icon code="xe634;" :size="16"> </Icon>
          <div class="more-name">个人设置</div>
        </div>
        <template v-else>
          <div class="bottom-left" @click="goPlatform">
            <Icon code="xe634;" style="margin: 2px 8px 0" :size="16"> </Icon>
            <div class="left-name">管理后台</div>
          </div>
          <div class="bottom-middle"></div>
          <a-popover
            placement="right"
            trigger="click"
            :overlayClassName="'space-content'"
            v-model:visible="morePopoverVisible"
          >
            <div class="bottom-left">
              <Icon code="xe719;" style="margin-right: 8px; cursor: pointer" :size="16"> </Icon>
              <div class="more-text">更多</div>
            </div>
            <template #content>
              <div class="more-popover" @click="handleInviteVisible(true)">
                <Icon code="xe643;" :size="16"> </Icon>
                <div class="more-name">邀请成员</div>
              </div>
              <div class="more-popover" @click="goPersonalSettings">
                <Icon code="xe634;" :size="16"> </Icon>
                <div class="more-name">个人设置</div>
              </div>
            </template>
          </a-popover>
        </template>
        <!-- <RouterLink to="/settings">管理后台</RouterLink> -->
      </div>
    </nav>
    <main class="main w-full flex flex-col">
      <UserBar>
        <template v-if="route.path !== '/settings/settings'" #extra>
          <div class="search-container ml-2">
            <a-input
              placeholder="搜索项目名称/项目文件名称"
              size="small"
              allowClear
              class="input-small"
              :style="{ width: '300px' }"
              v-model:value="keyword1"
              @change="handleChange"
            >
              <template #prefix>
                <search-outlined class="certain-category-icon" />
              </template>
            </a-input>
          </div>
        </template>
      </UserBar>
      <div v-if="!keyword" class="main-content">
        <RouterView></RouterView>
      </div>
      <SearchResult :keyword="keyword" @clear="handleClear" v-else></SearchResult>
    </main>
    <div class="feedback-content" @click="feedbackVisible = true">
      <Icon code="xe738;" :gutter="0" :size="20"></Icon>
    </div>
  </section>
  <PermissionManager
    v-model:visible="selectorVisible"
    :members="spaceMembers"
    :title="`空间权限 - ${editingSpaceName}`"
    @ok="handlePermissionOk"
    @transferOwner="handleTransferOwner"
  />
  <InviteModal
    :visible="inviteVisible"
    @close="handleInviteVisible(false)"
    :inviteToken="inviteToken"
  />
  <FeedbackModal v-model:visible="feedbackVisible" @cancel="feedbackVisible = false" />

  <Modal v-model:visible="createVisible" title="创建企业" @ok="handleOkCreate">
    <a-form
      labelAlign="left"
      ref="createFormRef"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 20 }"
      :model="createForm"
    >
      <BasicFormItem
        v-for="item in createDefs"
        :key="item.key"
        :item="item"
        v-model:value="createForm[item.key]"
      />
    </a-form>
  </Modal>
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { Modal, BasicFormItem, Avatar, Menu } from '@elecmap/elecmap-ui'
import { Icon } from '@elecmap/icon'
import {
  CaretDownOutlined,
  PlusOutlined,
  CheckOutlined,
  SearchOutlined,
} from '@ant-design/icons-vue'
import { onMounted, provide, reactive, ref, watch } from 'vue'
import {
  generateInvitedToken,
  createOrganization,
  listOrganization,
  changeOrganization,
} from '@elecmap/api/src/ram'
import type { IFormItem, IKeywords } from '@elecmap/elecmap-ui/lib/form/types'
import type { EmMenuItem } from '@elecmap/elecmap-ui/lib/menu/types'
import { storeToRefs } from 'pinia'
import { isDev } from '@elecmap/api/src/env'
import { message } from 'ant-design-vue'
import {
  listFolderAndProjectByName,
  listOrganizationSpaceMember,
  transferSpaceOwner,
  updateOrganizationSpaceMember,
} from '@elecmap/api/src/cad/space'
import type {
  CadFolderShortVo,
  CadFolderMemberVo,
  CadProjectShortVo,
} from '@elecmap/api/src/cad/space.d'
import { useOrganization } from '@/store/organization'
import InviteModal from '../views/member-manage/invite-modal.vue'
import PermissionManager from '../components/permission-manager.vue'
import UserBar from '../components/user-bar.vue'
import useUserStore from '@/store/user'
import { useSpaceStore } from '@/store/space'
import { debounce } from 'lodash-es'
import FeedbackModal from '../views/work-bench/feedback-modal.vue'
import SearchResult from '@/views/work-bench/search-result.vue'

const feedbackVisible = ref(false)
const store = useOrganization()
const { setOrganization, setCurrentOrganizationId } = store
const { organization, organizationId, currentOrganization } = storeToRefs(store)
const us = useUserStore()
const { userInfo } = storeToRefs(us)
const { setUser } = us
const spaceStore = useSpaceStore()
const { createOrgSpace, updateOrgSpace, deleteOrgSpace } = spaceStore
const { orgSpaceList, currentSpace, navs } = storeToRefs(spaceStore)

const route = useRoute()
const keyword1 = ref<string>()
const keyword = ref<string>()

const popoverVisible = ref(false)
const selectorVisible = ref(false)
const spaceMembers = ref<CadFolderMemberVo[]>([])
const spacePopoverVisible = ref(false)
const morePopoverVisible = ref(false)
const router = useRouter()

const inviteVisible = ref(false)
const inviteToken = ref()

const handleChange = debounce(() => {
  keyword.value = keyword1.value
}, 300)

function handleClear() {
  keyword.value = ''
  keyword1.value = ''
}

watch(
  () => route.path,
  () => {
    handleClear()
  },
)

const spaceMenus: EmMenuItem[][] = [
  [
    {
      name: '空间权限',
      key: 'permission',
      icon: '&#xe60e',
    },
    {
      name: '修改信息',
      key: 'edit',
      icon: '&#xe63a',
    },
  ],
  [
    {
      name: '删除',
      key: 'delete',
      icon: '&#xe718',
    },
  ],
]

async function handleTransferOwner(data: { userId: number; employeeId: number }) {
  await transferSpaceOwner({ folderId: editingSpaceId, ...data })
  const result = await listOrganizationSpaceMember(editingSpaceId)
  spaceMembers.value = result
}

function selectSpace(item?: CadFolderShortVo) {
  navs.value = []
  currentSpace.value = item
  router.replace({ path: '/workbench/index' })
}

function go(path: string) {
  selectSpace(undefined)
  router.push({ path })
}

const goChangeOrganization = (id: number) => {
  popoverVisible.value = false
  changeOrganization(id).then(res => {
    localStorage.removeItem('organizationId')
    setCurrentOrganizationId(id, res.organizationType)
    setUser(res)
    useSpaceStore().navs = []
    localStorage.setItem('refresh', '1')
    window.location.replace('/workbench')
    window.location.reload()
  })
}

async function handleRenameSpace(space: CadFolderShortVo) {
  // const data = await queryOrganizationSpace(space.id)
  Modal.form({
    width: '520px',
    title: '修改信息',
    formItems: [
      {
        key: 'name',
        label: '空间名称',
        placeholder: '请输入空间名称',
        type: 'Input',
        rules: [
          {
            required: true,
            message: '请输入',
          },
        ],
      },
      {
        key: 'description',
        label: '描述',
        placeholder: '请输入描述',
        type: 'TextArea',
      },
    ],
    formState: reactive({ keywords: { ...space }, tags: {} }),
    okText: '确认',
    onSubmit: ({ keywords }: any) => {
      updateOrgSpace({
        id: space.id,
        name: keywords.name,
        description: keywords.description,
      })
    },
  })
}

function handleDeleteSpace(folder: CadFolderShortVo) {
  Modal.confirm({
    title: `删除空间-${folder.name}`,
    content: [
      '请确认您要删除这个空间。',
      '这个空间内相关信息将会被永久删除。',
      '这是一个不可逆的操作，请谨慎对待。',
    ].join('<br />'),
    onOk: () => {
      deleteOrgSpace(folder.id)
    },
  })
}

const createFormRef = ref()
const createVisible = ref(false)
const createForm = ref<IKeywords>({})
const createDefs = reactive<IFormItem[]>([
  {
    key: 'organizationName',
    label: '企业名称',
    placeholder: '请输入企业名称',
    type: 'Input',
    rules: [
      {
        required: true,
        message: '请输入企业名称',
      },
    ],
  },
  {
    key: 'name',
    label: '你的昵称',
    placeholder: '请输入昵称',
    type: 'Input',
    // rules: [
    //   {
    //     required: true,
    //     message: '请输入',
    //   },
    // ],
  },
  {
    key: 'name2',
    label: '规模',
    placeholder: '请选择规模',
    type: 'Select',
  },
  {
    key: 'name3',
    label: '行业',
    placeholder: '请选择行业',
    type: 'Select',
  },
])
const openCreateModal = () => {
  popoverVisible.value = false
  createVisible.value = true
}
const handleOkCreate = () => {
  createFormRef.value.validate().then(() => {
    createOrganization({ organizationName: createForm.value.organizationName }).then((org: any) => {
      message.success('操作成功！')
      us.showInitialModal = true
      listOrganization().then((data = []) => {
        setOrganization(data)
        goChangeOrganization(org.id)
      })
      createVisible.value = false
    })
  })
}

const handleInviteVisible = (visible: boolean) => {
  morePopoverVisible.value = false
  inviteVisible.value = visible
  if (visible) {
    generateInvitedToken().then((res: any) => {
      inviteToken.value = res
    })
  }
}

let editingSpaceId = 0
const editingSpaceName = ref('')
const changePermission = async (space: CadFolderShortVo) => {
  const data = await listOrganizationSpaceMember(space.id)
  spaceMembers.value = data
  selectorVisible.value = true
  spacePopoverVisible.value = false
  editingSpaceId = space.id
  editingSpaceName.value = space.name
}

const goPlatform = () => {
  router.push({ path: '/settings' })
}

const goPersonalSettings = () => {
  router.push('/settings/settings')
  morePopoverVisible.value = false
}

function toDesignCenter(id?: number) {
  window.location.href = isDev ? 'http://127.0.0.1:8083' : '/design'
}

function createSpace() {
  Modal.form({
    width: '520px',
    title: '新建空间',
    formItems: [
      {
        label: '空间名称',
        placeholder: '请输入空间名称',
        type: 'Input',
        key: 'name',
        rules: [
          {
            required: true,
            message: '请输入空间名称',
          },
        ],
      },
      {
        label: '描述',
        placeholder: '请输入空间描述',
        type: 'TextArea',
        key: 'description',
      },
    ],
    formState: reactive({ keywords: {}, tags: {} }),
    okText: '确认',
    onSubmit: ({ keywords }: any) => {
      createOrgSpace(keywords)
    },
  })
}

async function handlePermissionOk(data: any[]) {
  const cmd = data.map(item => ({
    employeeId: item.id,
    folderId: editingSpaceId,
    memberPermission: item.memberPermission,
    userId: item.userId,
  }))
  await updateOrganizationSpaceMember(cmd)
  const result = await listOrganizationSpaceMember(editingSpaceId)
  spaceMembers.value = result
}

function handleSpaceMenuClick(key: string, item: CadFolderShortVo) {
  switch (key) {
    case 'permission':
      changePermission(item)
      break
    case 'edit':
      handleRenameSpace(item)
      break
    case 'delete':
      handleDeleteSpace(item)
      break
  }
}

onMounted(() => {
  listOrganization().then((data = []) => {
    setOrganization(data)
  })
  if (!spaceStore.orgSpaceList.length) {
    spaceStore.init()
  }
})
</script>
<style>
.avatar-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    width: 260px;

    .ant-popover-inner-content {
      padding: 4px;
    }

    .item-add {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0 8px 8px;

      .create-content {
        color: #268feb;
        line-height: 16px;
      }
    }

    .ant-divider-horizontal {
      margin: 12px 0;
    }

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      margin-bottom: 2px;
      transition: background-color 0.28s;

      &.current {
        background-color: #f5f7fa;
      }

      &:hover {
        background-color: #f5f7fa;
      }

      &:nth-last-child(3) {
        margin-bottom: 0;
      }

      .item-avatar {
        margin-right: 12px;
      }

      .item-content {
        display: flex;
        flex-direction: column;

        .content-one {
          font-size: 13px;
          margin-bottom: 6px;
          cursor: pointer;
        }

        .content-two {
          width: 52px;
          font-size: 12px;
          color: rgb(102, 102, 102);
          border-radius: 4px;
          padding-left: 6px;
          background-color: rgb(242, 242, 242);
        }

        .content-member {
          display: flex;

          .content-two {
            width: 52px;
          }

          .member {
            font-size: 12px;
            margin-left: 12px;
          }
        }
      }

      .current-mark {
        text-align: right;
        flex: 1;
      }
    }
  }
}

.space-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    width: 140px;

    .space-popover {
      display: flex;
      align-items: center;
      cursor: pointer;

      .space-name {
        font-size: 12px;
        margin-left: 8px;
      }
    }

    .more-popover {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 8px;

      .more-name {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
}

.space-dropdown {
  .ant-dropdown-content {
    width: 140px;

    .ant-menu-item-divider {
      transform: scaleX(0.86);
    }
  }
}
</style>
<style lang="scss" scoped>
.nav {
  flex: 0 0 225px;
  position: relative;
  overflow: hidden;

  .logo-section {
    padding: 24px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    .elec {
      font-size: 18px;
      color: #212930;
      margin-right: 4px;

      &.org {
        // background-color: #d7d7d7;
        border-radius: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        margin-left: 4px;
      }

      // &.logo {
      //   // font-weight: bold;
      // }
    }

    .click-area {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .map {
      font-size: 18px;
      // color: white;
      // background-color: #268feb;
      width: 48px;
      border-radius: 6px;
      margin-right: 2px;
      // padding-left: 5px;
    }

    .free {
      flex-shrink: 0;
      font-size: 12px;
      margin-left: 2px;
      color: #505050;
      background-color: #d7d7d7;
      width: 48px;
      height: 24px;
      border-radius: 4px;
      padding-left: 6px;
      padding-top: 2px;
    }
  }

  .button {
    width: 184px;
    height: 32px;
    margin-left: 20px;
    border-radius: 6px;
    font-size: 13px;
    background-color: #268feb;
    border: none;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin-top: 24px;

    .item-menu {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 2px -4px;
      padding: 4px 8px;
      border-radius: 3px;
      transition: background-color 0.2s ease-in-out;
      // .item-one {}

      .item-two {
        font-size: 13px;
      }

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }

      &.active {
        background-color: rgba(0, 142, 250, 0.1) !important;
      }
    }
  }

  .line {
    width: 180px;
    margin: 10px auto;
    height: 1px;
    background-color: #ddd;
  }

  .organizational-space {
    margin: 0 16px;

    .space-first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .first-left {
        font-size: 13px;
        color: rgb(136, 136, 136);
      }

      .first-right {
        cursor: pointer;
        margin-right: 4px;
        color: #212930;
        text-align: center;

        :deep(.anticon-plus) {
          font-size: 13px;
        }
      }
    }

    .space-item {
      display: flex;
      justify-content: space-between;
      margin: 0 -4px;
      margin-bottom: 4px;
      padding: 4px 8px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      border-radius: 3px;

      :deep(.em-icon:last-child) {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.05);
        :deep(.em-icon:last-child) {
          opacity: 1;
        }
      }

      &.active {
        background-color: rgba(0, 142, 250, 0.1) !important;
      }

      .space-left {
        display: flex;
        align-items: center;

        .left-text {
          font-size: 13px;
          margin-left: 8px;
        }
      }
    }
  }

  .bottom-line {
    position: fixed;
    bottom: 36px;
    width: 225px;
    // margin-left: 20px;
    height: 1px;
    background-color: #ddd;
  }

  .bottom {
    position: fixed;
    bottom: 4px;
    display: flex;
    // border-top: 1px #ddd solid;
    &.bottom-only {
      left: 8px;
    }

    .bottom-left {
      display: flex;
      cursor: pointer;
      align-items: center;

      .more-text {
        font-size: 13px;
      }

      .left-name {
        // margin-left: 4px;
        font-size: 13px;
      }
    }

    .bottom-middle {
      height: 20px;
      width: 1px;
      background-color: #bbb;
      margin: 4px 28px;
    }

    .bottom-right {
      display: flex;
      cursor: pointer;
    }
  }
}

.feedback-content {
  position: fixed;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #f8f8f8;
  bottom: 48px;
  right: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.main-content {
  overflow: hidden scroll;
}
</style>
