import http from '../client'
import type {
  CadFolderAndProjectShortVo,
  CadFolderMemberVo,
  CadFolderShortVo,
  CreateFolderCommand,
  CreateSpaceCommand,
  ListFolderTreeDto,
  ListOrganizationSpaceByPageVO,
  ListOrganizationSpaceCommand,
  MoveFolderCommand,
  RenameFolderCommand,
  TransferSpaceOwnerCommand,
  UpdateFolderCommand,
  UpdateOrganizationSpaceMemberCommand,
  UpdateSpaceCommand,
} from './space.d'
import type { PaginationData } from '../base.d'

export function listOrganizationSpace() {
  return http<CadFolderShortVo[]>({
    url: '/cad/api/folder/list-organization-space',
  })
}

export function listOrganizationFolder() {
  return http<CadFolderShortVo[]>({
    url: '/cad/api/folder/list-organization-folder',
  })
}

export function queryOrganizationSpace(id: number) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/query-organization-space',
    data: { id },
  })
}

export function queryOrganizationFolder(id: number) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/query-organization-folder',
    data: { id },
  })
}

export function createOrganizationSpace(data: CreateSpaceCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/create-organization-space',
    data,
  })
}

export function updateOrganizationSpace(data: UpdateSpaceCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/update-organization-space',
    data,
  })
}

export function createOrganizationFolder(data: CreateFolderCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/create-organization-folder',
    data,
  })
}

export function updateOrganizationFolder(data: UpdateFolderCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/update-organization-folder',
    data,
  })
}

export function listOrganizationSubFolderAndProject(id: number, name?: string) {
  return http<CadFolderAndProjectShortVo>({
    url: '/cad/api/folder/list-organization-sub-folder-and-project',
    data: { id, name },
  })
}

export function listProjectGraphicalPreview(projectIds: number[]) {
  return http<{ projectId: number; graphicalPreview: string }[]>({
    url: '/cad/api/project/list-project-graphical-preview',
    data: { projectIds },
  })
}

export function deleteOrganizationSpace(id: number) {
  return http({
    url: '/cad/api/folder/delete-organization-space',
    data: { id },
  })
}

export function deleteOrganizationFolder(id: number) {
  return http({
    url: '/cad/api/folder/delete-organization-folder',
    data: { id },
  })
}

export function deleteFolderProject(id: number) {
  return http({
    url: '/cad/api/folder-project/delete-folder-project',
    data: { id },
  })
}

export function listOrganizationSpaceMember(folderId: number) {
  return http<CadFolderMemberVo[]>({
    url: '/cad/api/folder/list-organization-space-member',
    data: { folderId },
  })
}

export function updateOrganizationSpaceMember(data: UpdateOrganizationSpaceMemberCommand[]) {
  return http({
    url: '/cad/api/folder/update-organization-space-member',
    data,
  })
}

export function listOrganizationFolderTree() {
  return http<ListFolderTreeDto[]>({
    url: '/cad/api/folder/list-organization-folder-tree',
  })
}

export function moveFolder(data: MoveFolderCommand) {
  return http({
    url: '/cad/api/folder/move-folder',
    data,
  })
}

export function movePersonalFolder(data: MoveFolderCommand) {
  return http({
    url: '/cad/api/folder/move-personal-folder',
    data,
  })
}

export function renameFolder(data: RenameFolderCommand) {
  return http({
    url: '/cad/api/folder/rename',
    data,
  })
}

export function listOrganizationSpaceByPage(data: ListOrganizationSpaceCommand) {
  return http<PaginationData<ListOrganizationSpaceByPageVO>>({
    url: '/cad/api/folder/list-organization-space-by-page',
    data,
  })
}

export function listPersonalSubFolderAndProject(id?: number) {
  return http<CadFolderAndProjectShortVo>({
    url: '/cad/api/folder/list-personal-sub-folder-and-project',
    data: id ? { id } : {},
  })
}

export function createPersonalFolder(data: CreateFolderCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/create-personal-folder',
    data,
  })
}

export function updatePersonalFolder(data: UpdateFolderCommand) {
  return http<CadFolderShortVo>({
    url: '/cad/api/folder/update-personal-folder',
    data,
  })
}

export function deletePersonalFolder(id: number) {
  return http({
    url: '/cad/api/folder/delete-personal-folder',
    data: { id },
  })
}

export function transferSpaceOwner(data: TransferSpaceOwnerCommand) {
  return http({
    url: '/cad/api/folder/transfer-space-owner',
    data,
  })
}

export function queryPersonalSpace() {
  return http<{ id: number }>({
    url: '/cad/api/folder/query-personal-space',
  })
}

export function listFolderAndProjectByName(data: { folderId?: number; name?: string }) {
  return http<CadFolderAndProjectShortVo>({
    url: '/cad/api/folder/list-folder-and-project-by-name',
    data,
  })
}
